import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"scrollable":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({class:!_vm.block ? 'ml-1' : '',attrs:{"elevation":"0","color":!_vm.block ? 'info' : '',"block":_vm.block,"large":!_vm.block,"rounded":!_vm.block,"outlined":!_vm.block},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-cog ")]),_vm._v(" "+_vm._s(_vm.$t('manage-teams'))+" ")],1)]}},{key:"default",fn:function(dialog){return [_c(VCard,[_c(VToolbar,{staticClass:"mb-5",attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('group-teams')))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){dialog.value = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VList,{staticClass:"striped",attrs:{"dense":""}},_vm._l((_vm.model),function(item){return _c(VListItem,{key:item.teamId,attrs:{"value":item,"active-class":"blue--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c(VListItemContent,[_c(VListItemTitle,[_c(VTextField,{attrs:{"dense":"","placeholder":"Official name","hide-details":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c(VListItemSubtitle,[(item.club)?_c('span',[_vm._v(_vm._s(item.club))]):_c('span',{staticClass:"red darken-1"},[_vm._v(_vm._s(_vm.$t('club-not-found')))]),_vm._v(", "+_vm._s(_vm.$t('internal-name'))+": "+_vm._s(item.orgName)+" ")])],1),_c(VCheckbox,{attrs:{"input-value":active,"color":"primary"},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})]}}],null,true)})}),1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"success","block":""},on:{"click":function($event){return _vm.saveTeamList()}}},[_vm._v(" Save ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }

import GroupsChips from '../../components/GroupManagement.component.vue'
import Mixins from '@/shared'
export default {
  name: 'CompetitionType',
  components: {
    GroupsChips
  },
  mixins: [Mixins],
  props: {
    type: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      list: {},
      groups: [],
      name: '',
      editable: false,
      group: {
        name: '',
        ageGroup: ''
      }
    }
  },
  computed: {
    typeData () {
      return this.type
    }
  },
  methods: {
    createCompetition () {
      const counter = this.$store.getters['CompetitonsModule/getIds'](this.$route.params.typeId).length
      this.$store.dispatch('CompetitonsModule/insert', {
        name: this.group.name,
        ageGroup: this.group.ageGroup,
        typeId: this.$route.params.typeId,
        order: counter + 1
      })
      this.group.name = ''
    },
    updated (input: {key: string, value: any, id: string}) {
      this.$store.state.CompetitonsModule.data[input.id][input.key] = input.value
      const id = input.id
      this.$store.dispatch('CompetitonsModule/patch', {
        id,
        [input.key]: input.value
      })
    },
    toggleEdit () {
      this.editable = true
    },
    updateTypeName (e) {
      const id = this.type.id
      this.$store.dispatch('CompetitionTypesModule/patch', { id, name: e }).then(() => {
        this.editable = false
      })
    },
    updateVisibility (e) {
      const id = this.type.id
      this.$store.dispatch('CompetitionTypesModule/patch', { id, visible_on_web: e })
    }
  }
}

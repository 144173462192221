
/* eslint-disable */
import Vue from 'vue'
import { Group } from '@/interfaces/group'
import ToolTipIcon from './ToolTipIcon.component.vue'
import GroupTeamManagement from '@/components/GroupTeamManagement.component.vue'
import draggable from 'vuedraggable'
import GroupsComponent from './Groups.component.vue'

// @vuese
export default Vue.extend({
  name: 'GroupManagement',
  props: {
    competition: {
      type: Object,
      default: function () {
        return {
          visible_on_web: false
        }
      }
    }
  },
  components: {
    GroupTeamManagement,
    ToolTipIcon,
    draggable
  },
  data () {
    return {
      group: {
        competitionId: this.competition.id,
        visible_on_web: false,
        order: 0,
        cup: false
      } as Group,
      drag: false
    }
  },
  computed: {
    groups: {
        get() {
          return this.$store.getters['GroupModule/filterByCompetition'](this.competition.id)
        },
        set(value: any) {
          value.forEach((group: any, index: any) => {
          const id = group.id;
          this.$store.dispatch('GroupModule/set', {
            id,
            order: index
          }).catch((error: any) => {
            console.log(error)
          })
        })
      }
    },
    visibility () {
      return this.competition.visible_on_web
    }
  },
  mounted () {
    this.$store.dispatch('GroupModule/fetchAndAdd', { where: [['competitionId', '==', this.competition.id]] })
  },
  methods: {
    updateGroup (group: Group, dialog: { value: boolean }) {
      const id = group.id
      this.$store.dispatch('GroupModule/set', {
        id,
        competitionId: group.competitionId,
        gender: group.gender,
        name: group.name,
        name_on_web: ('name_on_web' in group) ? group.name_on_web : null,
        visible_on_web: group.visible_on_web,
        disabled: ('disabled' in group) ? group.disabled : false,
        cup: ('cup' in group) ? group.cup : false
      }).then(() => {
        dialog.value = !dialog.value
      }).catch((error: any) => {
        console.log(error)
      })
    },
    insertGroup (dialog: { value: boolean }) {
      this.group.order = this.groups.length
      this.$store.dispatch('GroupModule/insert', this.group).then(() => {
        delete this.group.id
      })
      dialog.value = false
    }
  }
})

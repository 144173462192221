
import Mixins from '@/shared'
export interface TeamItem {
  name: string;
  orgName: string;
  teamId: string;
  clubId: string;
  checked: boolean;
}
// This component handles the assignment of teams playing in a group
export default {
  name: 'GroupTeamsManagement',
  mixins: [Mixins],
  props: {
    // The group that is managed
    group: {
      type: Object,
      required: true,
      default: function () {
        return { id: '', gender: '', teams: [] }
      }
    },
    // Competition age setting
    age: {
      type: Object,
      required: true,
      default: function () {
        return { name: 'Vaksin' }
      }
    },
    // Activator is a block?
    block: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dialog: false,
      file: '',
      teams: Array<TeamItem>(),
      model: Array<TeamItem>()
    }
  },
  watch: {
    dialog: function () {
      this.$store.dispatch('ClubsModule/fetchAndAdd')
      const ageCheck = (typeof this.age === 'object') ? this.age.name : this.age
      this.$store.dispatch('ClubTeamsModule/fetchAndAdd', { clauses: { where: [['gender', '==', this.group.gender], ['ageGroup', '==', ageCheck]], limit: 0 } }).then(() => {
        this.teams = this.$store.getters['ClubTeamsModule/filterForGroup'](this.group.gender, this.age).map((doc: { clubId: number; id: string; name: string }) => {
          return {
            club: (this.$store.state.ClubsModule.data[doc.clubId]) ? this.$store.state.ClubsModule.data[doc.clubId].name : undefined,
            clubId: doc.clubId,
            teamId: doc.id,
            name: doc.name,
            orgName: doc.name,
            checked: false
          }
        })
        this.populateList()
      })
    }
  },
  methods: {
    // @vuese
    // Saves the selected team to group
    saveTeamList () {
      const id = this.group.id
      this.model.forEach((element: { teamId: any; clubId: any }) => {
        if (!('clubId' in element)) {
          this.$store.dispatch('ClubTeamsModule/fetchById', element.teamId).then((team: { clubId: any }) => {
            element.clubId = team.clubId
          })
        }
      })
      // Dispatch and patches the object to document
      this.$store.dispatch('GroupModule/patch', {
        id,
        teams: Object.values(this.model).filter((e: any) => e.checked === true)
      }).then(() => {
        // Close dialog after successful patch
        this.populateList()
      }).catch((e: any) => {
        console.error(e)
      })
    },
    // @vuese
    // Populate list with all possible teams that fit the age setting of parent competition
    populateList () {
      this.model = Array<TeamItem>()
      const found = Array<string>()
      const teams = ('teams' in this.group) ? [...this.teams, ...this.$store.getters['GroupModule/filterTeamsByGroup'](this.group.id)[0].teams] : this.teams
      teams.reverse()
      // Filter and add to model if team is already assigned to group
      this.model = teams.filter(function (el: { teamId: string }) {
        if (found.indexOf(el.teamId) === -1) {
          found.push(el.teamId)
          return el
        }
      })
    }
  }
}
